import { render, staticRenderFns } from "./MediSnapCarouselHomePageTabs.vue?vue&type=template&id=824b4c00&"
import script from "./MediSnapCarouselHomePageTabs.vue?vue&type=script&lang=js&"
export * from "./MediSnapCarouselHomePageTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediTileSpecialization: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Tile/MediTileSpecialization.vue').default,MediTileProduct: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Tile/MediTileProduct.vue').default,BaseChips: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseChips.vue').default,BaseIcon: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,BaseButton: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default,MediSnapCarousel: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/SnapCarousel/MediSnapCarousel.vue').default,MediTabsItem: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Tabs/MediTabsItem.vue').default,MediTabs: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Tabs/MediTabs.vue').default,BaseContainer: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseContainer.vue').default})
